import {
	Button,
	Image,
	Input,
	Loader,
	ReactModal,
	ReactSwitch,
} from '@storybook';
import cn from 'classnames';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import Tippy from '@tippyjs/react';
import { ConfirmationModal } from 'components';
import { API_URL, MESSAGE, message } from 'constant';
import { kycSettingsState, loginState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { validURLLocal as validURL } from 'utils';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { useDashboradhook } from '../../routes-children/hooks/hook';
import {
	BackUpCodes,
	CompanyDetails,
	EncrptionKeySection,
	GenrateMultifactorAuth,
	LoadingAnimation,
	MFA_ACTIVE_MODAL,
	MFA_MESSAGES,
	MFA_MODAL_HEADING_MESSAGES,
	SubAccountDetails,
} from './components';
import { useLoadingAnimation } from '../stores';

import './general-settings.scss';

export const GeneralSettings = () => {
	//globle states
	const [kycSettings, setKycSettings] = useRecoilState(kycSettingsState);
	const {
		whitelistedDomains,
		_id: clientsDetailid,
		whitelabel,
		showPricing,
	} = kycSettings ?? {};

	const login = useRecoilValue(loginState);
	const { mfaEnabled, parentBusinessId, userId } = login ?? {};

	//local states
	const [domainInput, setDomainInput] = useState('');
	const [isInputVisible, setInputVisible] = useState(false);
	const [isInputError, setInputError] = useState(false);
	const [inputErrorMessage, setInputErrorMessage] = useState('');
	const [isDeleted, setDeleted] = useState<boolean>(false);
	const [deleteID, setDeleteId] = useState<string>('');
	const [isConfirmationModal, setIsConfirmationModal] =
		useState<boolean>(false);
	const [multiFactorModal, setMultifactorModal] = useState(false);
	const [mfaConfirmationModal, setMfaIsConfirmationModal] = useState(false);
	const [mfaEnable, setMfaEnabled] = useState(mfaEnabled);
	const [activeStepName, setActiveStepName] = useState<string>(
		MFA_ACTIVE_MODAL.RGISTER_MFA
	);
	const [verificationCodeValue, setVerificationCodeValue] =
		useState<string>('');

	//hooks
	const { post: addDomain, data: domainData, loading } = useNetwork();
	const { post: updateWhitelable } = useNetwork();
	const { post: updateShowPricing } = useNetwork();
	const { remove: removeDomain } = useNetwork();
	const { errorNotification, successNotification } = useNotification();
	const { getPipelineInit } = useDashboradhook();
	const { post, data: mfa_setup_data } = useNetwork();
	const { post: mfa_Verify, loading: mfa_verify_loading } = useNetwork();
	const {
		post: mfa_back_Codes,
		data: mfa_back_up_codes,
		loading: back_up_loading,
	} = useNetwork();

	const { checkUserWritePermission } = useUserRoles();
	const { updateStorage } = useLoadingAnimation();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.GeneralSettings),
		[checkUserWritePermission]
	);

	const { TITLE_MESSAGE } = useMemo(() => message, []);

	useEffect(() => {
		if (domainData) {
			if (domainData.whitelistedDomains) {
				setKycSettings(prev => ({
					...prev,
					whitelistedDomains: domainData.whitelistedDomains,
				}));
				successNotification('Domain Saved !');
				setInputVisible(false);
				setDomainInput('');
			} else if (domainData.message !== 'ok') {
				errorNotification(domainData.message);
			}
		}
		// eslint-disable-next-line
	}, [domainData]);

	const handleDeleteDomain = useCallback((id: string) => {
		setIsConfirmationModal(true);
		setDeleteId(id);
	}, []);

	useEffect(() => {
		if (isDeleted) {
			const deleteUrl = `${API_URL.CLIENT_DETAILS}/${clientsDetailid}?whitelistedDomain=${deleteID}`;

			removeDomain(deleteUrl).then(res => {
				if (res) {
					if (whitelistedDomains.length) {
						const newWhitelistedDomains = whitelistedDomains.filter(
							whitelistedDomain => {
								return whitelistedDomain._id !== deleteID;
							}
						);
						setKycSettings(prev => ({
							...prev,
							whitelistedDomains: newWhitelistedDomains,
						}));
						setDeleted(false);

						successNotification('Domain Removed !');
					}
				} else {
					errorNotification(MESSAGE.ERROR);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDeleted]);

	const handleDeleteModel = useCallback((isOpen: boolean, value: boolean) => {
		setIsConfirmationModal(isOpen);
		setDeleted(value);
	}, []);

	const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setInputError(false);
		setDomainInput(value);
	}, []);

	const handleClickAddDomainCancel = useCallback(() => {
		setInputVisible(false);
		setInputError(false);
		setDomainInput('');
	}, []);

	const handleClickAddDomain = useCallback(() => {
		setInputVisible(true);
	}, []);

	const handleClickAddDomainSave = useCallback(() => {
		if (!domainInput) {
			setInputError(true);
			setInputErrorMessage('Please enter domain address');
			return;
		}
		const newDomain = domainInput.toString().toLowerCase().trim();

		if (!validURL(newDomain) || newDomain.includes('!')) {
			setInputError(true);
			setInputErrorMessage('Invalid domain address');
			return;
		}

		const domainUrlList = (whitelistedDomains ?? []).map(whitelistedDomain => {
			return whitelistedDomain?.url;
		});

		if (domainUrlList.indexOf(newDomain) !== -1) {
			setInputError(true);
			setInputErrorMessage('This domain already exists.');
			return;
		}

		const payload = {
			whitelistedDomain: {
				url: newDomain,
			},
		};
		addDomain(`${API_URL.CLIENT_DETAILS}`, payload);
		// eslint-disable-next-line
	}, [domainInput]);

	const handleToggle = useCallback(
		async (checked: boolean) => {
			await updateWhitelable(`${API_URL.CLIENT_DETAILS}`, {
				whitelabel: checked,
			}).then(res => {
				if (Object.keys(res ?? {}).length !== 0) {
					setKycSettings(prev => ({ ...prev, whitelabel: checked }));
					updateStorage({ whitelabel: checked });
				}
			});

			getPipelineInit();

			// eslint-disable-next-line
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[addDomain]
	);

	const handleShowPricingToggle = useCallback(
		async (checked: boolean) => {
			await updateShowPricing(`${API_URL.CLIENT_DETAILS}`, {
				showPricing: checked,
			}).then(res => {
				if (Object.keys(res ?? {}).length !== 0) {
					setKycSettings(prev => ({ ...prev, showPricing: checked }));
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	

	const handleMfaSetup = useCallback(() => {
		const payload = {
			type: 'setup',
		};
		post(`${API_URL.MFA_SETUP}`, payload);
	}, [post]);

	const handleMultifactorToggle = useCallback(
		(togleValue?: any) => {
			if (togleValue) {
				handleMfaSetup();
				setMultifactorModal(true);
			} else {
				setMultifactorModal(false);
				setMfaIsConfirmationModal(true);
			}
		},
		[handleMfaSetup]
	);

	const handleCloseGenrateMfaModal = useCallback(() => {
		setMultifactorModal(false);
		setVerificationCodeValue('');
		setActiveStepName(MFA_ACTIVE_MODAL.RGISTER_MFA);
	}, []);

	const handleMfaConfirmationModel = useCallback(
		(isOpen: boolean, value: boolean) => {
			setMfaIsConfirmationModal(isOpen);
			if (value) {
				const payload = {
					type: 'disable',
				};
				post(`${API_URL.MFA_SETUP}`, payload);
				setMfaEnabled(false);
				successNotification(MFA_MESSAGES.MFA_DISABLED_SUCCESS);
			}
		},
		[post, successNotification]
	);

	const renderForm = useMemo(() => {
		return (
			<div className="form_container">
				<Input
					label=""
					inputType="text"
					autoFocus={true}
					placeholder="Type your domain address"
					handleChange={handleInputChange}
					value={domainInput}
					isError={isInputError}
					errorMessage={inputErrorMessage}
				/>
				<Button
					label={'Save'}
					type={'button__filled--primary btn-add-domain-save'}
					handleClick={handleClickAddDomainSave}
					loader={loading ? <Loader type="loader" dimension={20} /> : <></>}
					disabled={loading}
				/>
				<Button
					label={'Cancel'}
					type={'button__filled--primary btn-add-domain-cancel'}
					handleClick={handleClickAddDomainCancel}
				/>
			</div>
		);
		// eslint-disable-next-line
	}, [domainInput, inputErrorMessage, isInputError, loading]);

	const whitelistedDomainTable = useMemo(() => {
		return whitelistedDomains && whitelistedDomains.length ? (
			whitelistedDomains
				.map(({ url, _id }) => {
					return (
						<div key={_id} className="whitelisted-domains__table-row">
							<div className="table-row-text">
								<a
									className="table-row-text__a"
									rel="noreferrer"
									href={url ?? '#'}
									target={'_blank'}
								>
									{url ?? ''}
								</a>
							</div>
							{isUserPermissionWrite && (
								<div
									onClick={() => handleDeleteDomain(_id ?? '')}
									className="table-row-deleteBtn"
								>
									Delete
								</div>
							)}
						</div>
					);
				})
				.reverse()
		) : (
			<div className="no-data-available">
				<Image fileName={'empty-funds.svg'} className="no-data__image-box" />
				<div className="no-data-available__text">No Whitelisted Domain</div>
			</div>
		);
	}, [handleDeleteDomain, whitelistedDomains, isUserPermissionWrite]);

	const handleVerificationChange = useCallback((value: string) => {
		setVerificationCodeValue(value);
	}, []);

	const mfaBodyContent = useMemo(() => {
		switch (activeStepName) {
			case MFA_ACTIVE_MODAL.RGISTER_MFA:
				return (
					<GenrateMultifactorAuth
						mfa_data={mfa_setup_data}
						handleChange={handleVerificationChange}
					/>
				);
			case MFA_ACTIVE_MODAL.BACK_UP_CODE:
				return <BackUpCodes back_up_codes={mfa_back_up_codes} />;
			default:
				return <></>;
		}
	}, [
		activeStepName,
		handleVerificationChange,
		mfa_back_up_codes,
		mfa_setup_data,
	]);

	const handleMfaSave = useCallback(async () => {
		const payload = {
			mfaCode: verificationCodeValue,
			userId: userId,
		};
		const verifyrResp = await mfa_Verify(`${API_URL.MFA_VERIFY}`, payload);
		if (verifyrResp?.verified) {
			setMfaEnabled(true);
			await mfa_back_Codes(`${API_URL.BACK_UP_CODES}`, {
				type: 'generate',
				userId: userId,
			});
			setActiveStepName(MFA_ACTIVE_MODAL.BACK_UP_CODE);
			successNotification(MFA_MESSAGES.MFA_SUCCESS);
		} else {
			errorNotification(MFA_MESSAGES.INVALID_CODE);
		}
	}, [
		errorNotification,
		mfa_Verify,
		mfa_back_Codes,
		successNotification,
		userId,
		verificationCodeValue,
	]);

	const handleSaveButtonLabel = useMemo(() => {
		if (!mfa_verify_loading && !back_up_loading) {
			return 'Save';
		} else {
			return <Loader type="loader" dimension={20} />;
		}
	}, [back_up_loading, mfa_verify_loading]);

	const mfaButtonState = useMemo(() => {
		if (
			verificationCodeValue.length > 4 &&
			!mfa_verify_loading &&
			!back_up_loading
		) {
			return false;
		}
		return true;
	}, [back_up_loading, mfa_verify_loading, verificationCodeValue.length]);

	const mfaBtnShowScreenWise = useMemo(() => {
		if (activeStepName === MFA_ACTIVE_MODAL.RGISTER_MFA) {
			return true;
		} else return false;
	}, [activeStepName]);

	const handleMfaHeading = useMemo(() => {
		switch (activeStepName) {
			case MFA_ACTIVE_MODAL.RGISTER_MFA:
				return MFA_MODAL_HEADING_MESSAGES.genrateCode.heading;

			case MFA_ACTIVE_MODAL.BACK_UP_CODE:
				return MFA_MODAL_HEADING_MESSAGES.backupCode.heading;
			default:
				return '';
		}
	}, [activeStepName]);

	const handleMfaSubHeading = useMemo(() => {
		switch (activeStepName) {
			case MFA_ACTIVE_MODAL.RGISTER_MFA:
				return MFA_MODAL_HEADING_MESSAGES.genrateCode.subHeading;

			case MFA_ACTIVE_MODAL.BACK_UP_CODE:
				return MFA_MODAL_HEADING_MESSAGES.backupCode.subHeading;
			default:
				return '';
		}
	}, [activeStepName]);

	return (
		<>
			<div className="general-settings-page">
				<div className="company-details-container hover w-50-8-gap-small-100 ">
					<CompanyDetails isUserPermissionWrite={isUserPermissionWrite} />
				</div>
				{/* Sub-account Details */}
				{parentBusinessId ? (
					<div className="company-details-container hover w-50-8-gap-small-100 ">
						<SubAccountDetails />
					</div>
				) : null}

				{/* White Labeling */}

				{/* Encription Key */}
				<div className="whitelabel-settings-wrapper hover w-50-8-gap-small-100 ">
					<EncrptionKeySection />
				</div>
				{/*Encription Key ends here */}

				<div className="whitelisted-domains hover w-50-8-gap-small-100 ">
					<div className="whitelisted-domains__header">
						<div className="whitelisted-domains__header__text">
							Whitelisted Domains
						</div>
						{isUserPermissionWrite && (
							<Button
								label={'Add Domain'}
								type={'button__filled--primary btn-add-domain'}
								handleClick={handleClickAddDomain}
								disabled={isInputVisible}
							/>
						)}
					</div>
					{isInputVisible && renderForm}
					<div
						className={cn('whitelisted-domains__table', {
							activeForm: isInputVisible,
						})}
					>
						{whitelistedDomainTable}
					</div>
				</div>

				<div className="whitelabel-settings-wrapper hover w-50-8-gap-small-100 ">
					<div className="whitelabel-settings whitelabel-settings__loading-animate">
						<div className="whitelabel-settings__label-wrapper">
							<div className="whitelabel-settings__label-icon">
								<i className="ri-price-tag-2-line whitelabel-settings__icon" />
							</div>
							<div className="whitelabel-settings__label-text">
								Enable white label
							</div>
						</div>
						<Tippy
							disabled={isUserPermissionWrite}
							content="You are not permitted to toggle white label"
						>
							<div className="whitelabel-settings__action">
								<ReactSwitch
									handleChange={handleToggle}
									checked={whitelabel}
									id="whitelable-toggle"
									onColor="#33b87a"
									isDisabled={!isUserPermissionWrite}
								/>
							</div>
						</Tippy>
						{whitelabel && isUserPermissionWrite && <LoadingAnimation />}
					</div>
					<div className="whitelabel-settings">
						<div className="whitelabel-settings__label-wrapper">
							<div className="whitelabel-settings__label-icon">
								<i className="ri-shield-fill whitelabel-settings__icon"></i>
							</div>
							<div>
								<div className="whitelabel-settings__label-text">
									Two-factor authentication
								</div>
							</div>
						</div>
						<Tippy
							disabled={isUserPermissionWrite}
							content="You are not permitted to toggle Two-factor authentication"
						>
							<div className="whitelabel-settings__action">
								<ReactSwitch
									handleChange={handleMultifactorToggle}
									checked={mfaEnable ?? false}
									id="whitelable-toggle"
									onColor="#33b87a"
									isDisabled={!isUserPermissionWrite}
								/>
							</div>
						</Tippy>
					</div>

					<div className="whitelabel-settings">
						<div className="whitelabel-settings__label-wrapper">
							<div className="whitelabel-settings__label-icon">
								<i className="ri-wallet-3-fill whitelabel-settings__icon"></i>
							</div>
							<div>
								<div className="whitelabel-settings__label-text">
									Show Pricing
								</div>
							</div>
						</div>
						<Tippy
							disabled={isUserPermissionWrite}
							content="You are not permitted to toggle Two-factor authentication"
						>
							<div className="whitelabel-settings__action">
								<ReactSwitch
									handleChange={handleShowPricingToggle}
									checked={showPricing}
									id="whitelable-toggle"
									onColor="#33b87a"
									isDisabled={!isUserPermissionWrite}
								/>
							</div>
						</Tippy>
					</div>
				</div>

				<ConfirmationModal
					visible={isConfirmationModal}
					title={TITLE_MESSAGE}
					description={
						'Deleting this domain is permanent and cannot be undone.'
					}
					handleModal={handleDeleteModel}
					label="Delete"
				/>
				<ReactModal
					header={handleMfaHeading}
					subheading={handleMfaSubHeading}
					body={mfaBodyContent}
					isModalOpen={multiFactorModal}
					disableButton={mfaButtonState}
					isShowButton={mfaBtnShowScreenWise}
					handleClose={handleCloseGenrateMfaModal}
					handleSave={handleMfaSave}
					saveButtonLabel={handleSaveButtonLabel}
					styleClass={'react-modal genrate-modal-wrapper'}
				/>
				<ConfirmationModal
					visible={mfaConfirmationModal}
					title={'Are you sure ?'}
					description={
						'Deleting will permanantely remove this file from the system. This can’t be undone.'
					}
					handleModal={handleMfaConfirmationModel}
					label="Save"
				/>
			</div>
		</>
	);
};
